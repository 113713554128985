import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { Cover, FAQs, GridResults, Layout, SectionTextMedia, SEO } from 'components';
import { routes } from 'utils/routes';
import { maintainerFaqsData, maintainerTCsData } from 'utils/config/faqs';
import reviewSelfies from 'utils/config/reviewSelfies';

import content from './maintainer-plan.yml';

const MaintainerPlanPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Cover
      title='Maintainer Plan'
      text='Never pay for braces again!<br/>This subscription is a unique hassle free way to maintain your new smile.'
      image={{ url: 'pages/maintainer_cover.jpg', alt: 'Woman holding her teeth mould' }}
      button={{ label: 'Get started', ...routes.getStarted }}
      className='cover-whitening'
    />

    <T id="maintainer.plan">{txt =>
      <SectionTextMedia
        title={`Join the Maintainer Plan – ${txt} / year`}
        text='Protect your investment and keep your smile straight.<br/><br/>Get fresh new retainers every 6 months to keep your teeth straight.<br/><br/>In the event that your teeth move, get up to 3 sets of aligners to straighten them again.<br/><br/>We keep your 3D files in the system so we can create new aligners for you at any point.'
        image={{ url: 'pages/maintainer_one.jpg', alt: 'Top view of all the straight teeth direct kit components' }}
        className='small-media'
      />
    }</T>

    <FAQs title='Before getting started' faqsData={maintainerFaqsData} />

    <FAQs title='View the Terms & Conditions' faqsData={maintainerTCsData} className='mb-90 mt-90' />

    <h2 className='section-title mb-50'>#MySmileDelivered reviews</h2>

    <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction className='mb-90' />
  </Layout>
);

MaintainerPlanPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default MaintainerPlanPage;
